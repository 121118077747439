"use strict";

import * as gtm from './tracking/gtm';
import $ from "jquery";
import {getPrefixedDataSet} from '@elements/data-set-utils';
import {isValid, loadParsley, isParsleyForm} from "@elements/parsley-bootstrap-validation";

export const initGtmEventTracking = (options) => {
    return createEventTracking(gtm, options);
}

function createEventTracking({setup,trackEvent}, {dataAttributePrefix = 'event-tracking', debug = false} ) {
    return ($scope) => {
        setup({debug});

        if ($scope.find(`.js-anchor-nav[data-${dataAttributePrefix}]`).length > 0){
            $(window).on('load', function () {
                trackDataAttributeTracking($scope, dataAttributePrefix, trackEvent, debug);
            })
        } else {
            trackDataAttributeTracking($scope, dataAttributePrefix, trackEvent, debug);
        }


        $scope.find(`[data-${dataAttributePrefix}-key]:not(.js-go-to-link), [data-${dataAttributePrefix}-key].js-go-to-link a`).each(function (index, trackingElement){
            // needed because of js-go-to-link possibility
            let $trackingElement = $(trackingElement).data(`${dataAttributePrefix}-key`) ? $(trackingElement) : $(trackingElement).closest(`[data-${dataAttributePrefix}-key]`);

            let trackingObjectKey = $trackingElement.data(`${dataAttributePrefix}-key`),
                trackingObject = _trackingData[trackingObjectKey];

            if (trackingObject) {
                let trigger = trackingObject.trigger || 'click';

                if (trigger === 'ajax-form'){
                    $trackingElement.on('fetched.ajax-form', function(evt, param) {
                        let result = param;
                        let element = evt.target;

                        if (trackingObject.responseTracking === true){
                            if (result){
                                resultTracking(trackEvent, result);
                            }else{
                                console.warn('No Result');
                            }
                        }else{
                            let clickedTrackingObjectKey = $(element).data(`${dataAttributePrefix}-key`);
                            if (_trackingData[clickedTrackingObjectKey]) {
                                trackEvent(_trackingData[clickedTrackingObjectKey], {debug});
                            }
                        }
                    });
                }else{
                    // $(document.body).on(trigger, trackingElement, function (evt) {
                    $(trackingElement).on(trigger, function (evt) {
                        // evt.preventDefault();

                        let triggeredElement = evt.currentTarget;

                        // needed because of js-go-to-link possibility
                        let $trackingElement = $(triggeredElement).data(`${dataAttributePrefix}-key`) ? $(triggeredElement) : $(triggeredElement).closest(`[data-${dataAttributePrefix}-key]`);

                        let clickedTrackingObjectKey = $trackingElement.data(`${dataAttributePrefix}-key`);
                        let trackingNeededClass = _trackingData[clickedTrackingObjectKey].neededClass;
                        let trackingIgnoreClass = _trackingData[clickedTrackingObjectKey].ignoreClass;
                        let trackingTrigger = _trackingData[clickedTrackingObjectKey].trigger;
                        let trackingFormValidator = _trackingData[clickedTrackingObjectKey].validator;
                        let prevent = _trackingData[clickedTrackingObjectKey].prevent;

                        if (prevent){
                            evt.preventDefault();
                        }

                        if (trackingFormValidator && trackingFormValidator === 'parsley'){
                            loadParsley().then(function () {
                                if (isParsleyForm($(triggeredElement)) && isValid($(triggeredElement))) {
                                    if (clickedTrackingObjectKey) {
                                        if (_trackingData && _trackingData[clickedTrackingObjectKey]) {
                                            trackEvent(_trackingData[clickedTrackingObjectKey], {debug});
                                        }
                                    }
                                }else{
                                    console.warn('Form not Valid');
                                }
                            });

                        }else{
                            if(!trackingNeededClass && !trackingIgnoreClass
                                || $trackingElement.hasClass(trackingNeededClass) && !$trackingElement.hasClass(trackingIgnoreClass)
                                || !trackingNeededClass && !$trackingElement.hasClass(trackingIgnoreClass)){

                                if (_trackingData[clickedTrackingObjectKey]) {
                                    trackEvent(_trackingData[clickedTrackingObjectKey], {debug});
                                }
                            }
                        }



                    });
                }
            }

        });

    }
}

function trackDataAttributeTracking($scope, dataAttributePrefix, trackEvent, debug) {
    $scope.find(`[data-${dataAttributePrefix}-event]`).each(function (index, element) {
        let dataEventTracking = getPrefixedDataSet(dataAttributePrefix, $(element));
        let trigger = dataEventTracking.trigger || 'click';

        if (trigger === 'ajax-form') {
            // For ajax-form sent
            $(element).on('fetched.ajax-form', function (evt) {
                let result = evt.detail;
                if (dataEventTracking.responseTracking === true) {
                    if (result) {
                        resultTracking(result);
                    } else {
                        console.error('No Result');
                    }
                } else {
                    let data = {...dataEventTracking};
                    delete data.trigger
                    trackEvent(data, {debug});
                }
            });
        } else {
            $(element).on(trigger, function () {
                let data = {...dataEventTracking};
                delete data.trigger
                trackEvent({datalayer: data}, {debug});
            });
        }
    });
}

export function responseTracking(request) {
    // Unpack json response body if the promise was created via fetch
    let promise = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    promise.then(result => {
        // window.dataLayer.push(result['trackingCode'])
        if (result['__trackingScript']) {
            try {
                eval(result['__trackingScript']);
            } catch (e) {
                console.error(e)
            }
        }
    });
}

export function resultTracking(trackEvent, result) {
    if (result.__trackingScript) {
        try {
            trackEvent({datalayer: result.__trackingScript}, {debug: true});
        } catch (e) {
            console.error(e)
        }
    }
}