"use strict";

import * as gtm from './tracking/gtm';
import $ from "jquery";
import {isValid, loadParsley, isParsleyForm} from "@elements/parsley-bootstrap-validation";

export function initInScope(options) {
    return createFormTracking(gtm, options);
}

function createFormTracking({setup,trackEvent}, {dataAttributePrefix = 'form-tracking', debug = false} ) {
    return ($scope) => {
        setup({debug});

        $scope.find('.js-form-tracking').each(function (index, element){
            let $form = $(this);

            $form.on($form.data(`${dataAttributePrefix}-trigger`), function (e) {
                let datalayer = {
                    'event': $form.data(`${dataAttributePrefix}-event`)
                };

                if (isParsleyForm($form)) {
                    if (isValid($form)) {
                        trackFormInputs($form, dataAttributePrefix, datalayer, trackEvent, debug);
                    }
                } else {
                    trackFormInputs($form, dataAttributePrefix, datalayer, trackEvent, debug);
                }
            });
        });
    }
}

function trackFormInputs($form, dataAttributePrefix, datalayer, trackEvent, debug) {
    const dataAttributeName = `${dataAttributePrefix}-input-param-name`;
    $form.find(`[data-${dataAttributeName}]`).each(function () {
        let $input = $(this);
        if ($input.is('select')) {
            datalayer[$input.data(dataAttributeName)] = $input.find(':selected').text();
        } else if ($input.attr('type') === 'checkbox') {
            datalayer[$input.data(dataAttributeName)] = $input.prop('checked');
        } else {
            let value = $input.val();
            if (value && value !== '' && $input.data(`${dataAttributePrefix}-format-date`)) {
                value = value.split('T')[0].split('-').reverse().join('/');
            }
            datalayer[$(this).data(dataAttributeName)] = value;
        }
    });

    trackEvent({datalayer}, {debug});
}