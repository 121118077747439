import {trackEvent} from './tracking/gtm'

/**
 * Track link click
 * @param {String} targetCls
 * @param {String} eventName
 */
export function trackLinkClick(targetCls, eventName) {
    const trackingTarget = document.querySelector(targetCls);

    trackingTarget?.addEventListener('click', (e) => {
        window.dataLayer = window['dataLayer'] || [];

        const data = {
            "datalayer": {
                "event": eventName,
                "click_item": e.target.innerText,
                "click_url": e.target.href
            }
        }

        trackEvent({datalayer: data}, {debug: false});
    });

}
